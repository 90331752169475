<template>
    <div class="page">
        <div class="page-box">
            <div class="head-box">
                <div class="back-btn" v-if="route.isBack()">
                    <Icon type="md-arrow-round-back" @click="route.back()" size="24" />
                </div>
                <div class="community-box">
                    <span style="padding-top:5px">已搭建体系：</span>
                    <Select v-model="grassrootsOrgIdx" style="width: 300px">
                        <Option v-for="(item, idx) in grassrootsOrgList" :value="idx" :key="item.rootOrgId">{{ item.rootOrgName }}</Option>
                    </Select>
                    <Button type="primary" style="margin-left:10px" @click="$refs.add_form.display()">新增架构</Button>
                    <Button type="info" style="margin-left:10px" @click="onJumpManagement">架构管理</Button>
                </div>
                <div class="group-box" v-if="category !== 'personnel'">
                    <div class="flex" v-if="groupInfo">
                        <Button icon="md-checkmark">已创建街镇社区（村）两委联席群</Button>
                        <Button type="error" style="margin-left: 10px;" @click="onDeleteGroup">删除</Button>
                    </div>
                    <Button v-else type="info" icon="md-add" @click="$refs.group_form_modal.display()">创建街镇社区（村）两委联席群</Button>
                </div>
            </div>
            <div class="content-box">
                <div class="left-box">
                    <div class="title-box">动员体系搭建流程</div>
                    <FlowTree :node="tree" :unfold="false" color="#d1d1d1"></FlowTree>
                </div>
                <div class="right-box">
                    <ElemTable ref="elem_table" title="畅联组" height="100%" :head="false" :columns="columns" :table-api="tableApi">
                        <template #type="evt">{{ groupTypes[evt.row.groupType] || "其他" }}</template>
                        <template #count="evt">群成员数：{{ evt.row.memberCount }}</template>
                        <template #operate="evt">
                            <Button size="small" type="info" @click="route.jump('/groupeasycombination')" style="height:28px;padding:0 10px;margin:0 5px">进入</Button>
                            <Button size="small" @click="onUpdateGroupMember(evt.row)" style="height:28px;padding:0 10px;margin:0 5px">更新成员</Button>
                        </template>
                    </ElemTable>
                </div>
            </div>
        </div>

        <AddForm ref="add_form" :category="category" @on-submit="onSubmitAddForm" :excludeOrgTypes="excludeOrgTypes"></AddForm>

        <CompModal ref="group_form_modal" title="创建街镇社区（村）两委联席群" height="auto" @on-close="group_formdata = {}">
            <div style="padding: 20px 30px 20px 20px">
                <ElemFormItem title="群名称" name="groupName">
                    <Input style="width:350px" v-model="group_formdata.groupName" :maxlength="50" placeholder="请填写群名称"></Input>
                </ElemFormItem>
            </div>
            <template #operate="evt">
                <div class="operate-btns">
                    <div class="operate-btn highlight" @click="onSubmitCreateGroup">保存</div>
                    <div class="operate-btn" @click="evt.close">取消</div>
                </div>
            </template>
        </CompModal>
    </div>
</template>

<script>
import FlowTree from "./components/FlowTree.vue"
import ElemTable from "./components/ElemTable.vue"
import CompModal from "@/views/residentdatabase/components/CompModal.vue"

import AddForm from "./children/AddForm"
import UserInfo from "./entity/UserInfo"
import Route from "./entity/Route"

export default {
    components: { FlowTree, ElemTable, CompModal, AddForm },

    data() {
        return {
            route: Route,
            tree: null,

            grassrootsOrgIdx: null,
            grassrootsOrgList: null,

            columns: [
                {
                    title: "类型",
                    width: 150,
                    slot: "type",
                },
                {
                    title: "名称",
                    key: "groupName",
                    width: 300,
                },
                {
                    title: "群成员数",
                    width: 150,
                    slot: "count",
                },
                {
                    title: "操作",
                    width: 200,
                    slot: "operate",
                    float: "right",
                },
            ],

            groupTypes: {},
            tableApi: "",
            group_formdata: {},
            // 群信息
            groupInfo: null,
            // 排除已建的架构模版
            excludeOrgTypes: new Set(),
            // 类型
            category: this.$core.getUrlParam("c"),
        }
    },

    watch: {
        async grassrootsOrgIdx() {
            let imps = await this.getImportState()
            let gs = await this.getGrassrootsOrgStatus()
            // 处理树结构
            this.tree = this.getTree(imps, gs.orgAdmin, gs.org, gs.orgAdminPost, gs.configGroup)
            this.checkIsCreateGroup()
        },
    },

    created() {
        this.tree = this.getTree()
    },

    async mounted() {
        await this.getGroupTypes()
        // 表格接口
        this.tableApi = `/sendmsg/pc/chat/groupCreate/queryPage?orgCode=${UserInfo.get("orgCode")}&oemCode=${parent.vue.oemInfo.oemCode}&custGlobalId=${UserInfo.get("custGlobalId")}`

        await this.onProcessData()
        this.checkIsCreateGroup()
    },

    methods: {
        async onProcessData() {
            await this.getAllGrassrootsOrg()
        },

        async checkIsCreateGroup() {
            this.$get("/gateway/org/pc/grassrootsOrg/v2/checkRootNodeGroupExist", {
                rootOrgId: this.grassrootsOrgList[this.grassrootsOrgIdx].rootOrgId,
            }).then(res => {
                this.groupInfo = res.code == 200 && res.data && Object.keys(res.data).length > 0 ? res.data : null
            })
        },

        getGroupTypes() {
            return this.$get("/dictlist/api/lfcommon/pc/dict/selectDictDataList", {
                dictType: "CHAT_GROUP_TYPE",
                appCode: "",
            }).then(res => {
                if (res.code == 200) {
                    let obj = {}

                    for (let i = 0, l = res.dataList; i < l.length; i++) {
                        let v = l[i]
                        obj[v.dictKey] = v.dictValue
                    }

                    this.groupTypes = obj
                }
            })
        },

        getTree(imps = 0, us = 0, orgs = 0, ps = 0, gs = 0) {
            let pags = !ps && !gs ? 0 : ps == 2 && gs == 2 ? 2 : 1

            return this.category === "personnel"
                ? {
                      content: "1.导入人员",
                      width: "270px",
                      click: () => {
                          Route.jump("/residentdatabasemybusiness?type=DYTX")
                      },
                      child: {
                          content: "2.搭建体系架构",
                          width: "270px",
                          sign: {
                              title: ["未搭建", "已搭建"][orgs],
                              style: this.getSignStyle(orgs == 0 ? 0 : 2),
                          },
                          click: () => {
                              this.onJumpManagement()
                          },
                          child: {
                              content: "3.设置相关负责人并建群",
                              width: "270px",
                              sign: {
                                  title: ["未设置", "进行中", "已完成"][pags],
                                  style: this.getSignStyle(pags),
                              },
                              click: () => {
                                  this.onJumpManagement()
                              },
                          },
                      },
                  }
                : {
                      content: "1.导入社区居民名册、网格党支部、党小组",
                      sign: {
                          title: ["未导入", "已导入部分", "已导入部分", "导入完成"][imps],
                          style: this.getSignStyle(imps),
                      },
                      height: "130px",
                      width: "270px",
                      click: () => {
                          Route.jump("/importmessage")
                      },
                      child: [
                          {
                              content: "2.人员管理",
                              width: "125px",
                              sign: {
                                  title: ["无数据", "有数据"][us],
                                  style: this.getSignStyle(us == 0 ? 0 : 2),
                              },
                              click: () => {
                                  Route.jump("/communitymobilizationmember")
                              },
                          },
                          {
                              content: "2.搭建体系架构",
                              width: "125px",
                              sign: {
                                  title: ["未搭建", "已搭建"][orgs],
                                  style: this.getSignStyle(orgs == 0 ? 0 : 2),
                              },
                              click: () => {
                                  this.onJumpManagement()
                              },
                          },
                          {
                              type: "merge",
                              content: "3.设置相关负责人并建群",
                              width: "270px",
                              sign: {
                                  title: ["未设置", "进行中", "已完成"][pags],
                                  style: this.getSignStyle(pags),
                              },
                              click: () => {
                                  this.onJumpManagement()
                              },
                          },
                      ],
                  }
        },

        // 获取状态标记样式
        getSignStyle(s) {
            switch (s) {
                case 0:
                    return {
                        background: "#e3e3e3",
                        color: "#888",
                    }
                case 1:
                    return {
                        background: "#43aaea",
                        color: "#fff",
                    }
                default:
                    return {
                        background: "#2ac82a",
                        color: "#fff",
                    }
            }
        },

        getImportState() {
            return new Promise(resolve => {
                this.$get("/gateway/sy-user/sy-user/pc/sy/user/gridManage/getImportState", {
                    communityCode: UserInfo.getCommunityCode(),
                }).then(res => {
                    if (res.code == 200) {
                        resolve(res.data)
                    } else this.$Message.error(res.desc)
                })
            })
        },

        getGrassrootsOrgStatus() {
            return new Promise(resolve => {
                this.$get("/gateway/org/pc/grassrootsOrg/getGrassrootsOrgStatus", {
                    communityCode: UserInfo.getCommunityCode(),
                    orgType: this.grassrootsOrgList[this.grassrootsOrgIdx].orgType,
                }).then(res => {
                    if (res.code == 200) {
                        resolve(res.data)
                    } else this.$Message.error(res.desc)
                })
            })
        },

        async getAllGrassrootsOrg() {
            await this.$get("/gateway/org/pc/grassrootsOrg/selectTopList", {
                communityCode: UserInfo.getCommunityCode(),
            }).then(res => {
                if (res.code === "200") {
                    let list = res.dataList

                    // 根据架构类型过滤
                    list = list.filter(f => (this.category === "personnel" ? f.rootType == 2 : f.rootType != 2))

                    let orgTypes = new Set()

                    // 排除已建架构
                    for (let i = 0; i < list.length; i++) {
                        orgTypes.add(list[i].orgType)
                    }

                    this.excludeOrgTypes = orgTypes
                    this.grassrootsOrgList = list

                    if (list?.length > 0) {
                        this.grassrootsOrgIdx = 0
                    }
                }
            })
        },

        onJumpManagement() {
            if (this.grassrootsOrgIdx === null) {
                return this.$Message.error("请选择架构")
            }

            Route.jump("/communitymobilizationeditframework", {
                i: this.grassrootsOrgList[this.grassrootsOrgIdx].rootOrgId,
                c: this.category,
            })
        },

        /**
         * 更新群成员
         */
        onUpdateGroupMember(v) {
            this.$post("/sendmsg/pc/chat/grid/syncRootOrgGroupMember", {
                groupId: v.groupId,
            }).then(res => {
                if (res.code == 200) {
                    this.$Message.success("更新成功")
                    // 刷新表格
                    setTimeout(() => {
                        this.$refs.elem_table.refresh()
                    }, 1000)
                } else this.$Message.error(res.desc || "数据请求异常，请稍后再试！")
            })
        },

        // onSubmitCreateFramework() {
        //     this.$post("/gateway/org/pc/grassrootsOrg/generateNextRootOrg", {
        //         rootId:
        //     }).then(res => {
        //         console.log(res);
        //     })
        //     console.log("submit")
        // },

        async onSubmitCreateGroup() {
            let fd = this.group_formdata

            if (!fd.groupName) {
                return this.$Message.error("请输入群名称")
            }

            this.$post(
                "/gateway/org/pc/grassrootsOrg/v2/createRootTopGroup",
                {
                    communityCode: UserInfo.getCommunityCode(),
                    orgCodeSelf: UserInfo.get("orgCode"),
                    groupName: fd.groupName,
                    rootOrgId: this.grassrootsOrgList[this.grassrootsOrgIdx].rootOrgId,
                    rootOrgLevel: 1,
                    creatorId: UserInfo.get("custGlobalId"),
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                if (res.code == 200) {
                    this.$Message.success("创建成功")
                    // 刷新表格
                    this.$refs.elem_table.refresh()
                    // 关闭弹窗
                    this.$refs.group_form_modal.close()
                    // 已创建
                    this.checkIsCreateGroup()
                } else this.$Message.error(res.desc)
            })
        },

        onSubmitAddForm(evt) {
            Route.jump("/communitymobilizationeditframework", {
                i: evt.value.parentId || evt.value.rootOrgId,
                c: this.category,
            })
        },

        onDeleteGroup() {
            this.$Modal.confirm({
                title: "提示",
                content: "确定删除该畅联组吗？",
                onOk: () => {
                    this.$post("/sendmsg/pc/chat/groupCreate/deleteGroup", {
                        groupId: this.groupInfo.groupId,
                    }).then(res => {
                        if (res.code != 200) {
                            return this.$Message.error(res.desc || res.msg || "请求失败")
                        }

                        this.$Message.success("删除成功")
                        this.groupInfo = null
                    })
                },
            })
        },
    },
}
</script>

<style lang="less" scoped>
.page {
    padding: 20px;
    background: #f9f9f9;
    height: 100%;

    .page-box {
        margin: 0 auto;
        max-width: 1340px;
        min-width: 900px;
        height: 100%;
        display: flex;
        flex-direction: column;

        .head-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 30px;
            flex-shrink: 0;

            .back-btn {
                margin-left: 10px;
                cursor: pointer;
                display: flex;
                align-items: center;
            }

            .community-box {
                width: 100%;
                margin-left: 10px;
                display: flex;
            }

            .group-box {
                display: flex;

                .flex {
                    display: flex;
                }
            }
        }
    }

    .content-box {
        display: flex;
        flex-grow: 1;
        overflow: hidden;

        .left-box {
            flex-shrink: 0;
            overflow-y: auto;

            .title-box {
                font-size: 20px;
                line-height: 1;
                text-align: center;
            }

            .tree-item {
                padding: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                box-sizing: border-box;
                text-align: center;
            }
        }

        .right-box {
            flex-grow: 1;
            overflow: hidden;
        }
    }
}

.add-framework-form-box {
    padding: 20px;
    display: flex;
    align-items: center;

    .title {
        margin-right: 10px;
        flex-shrink: 0;
    }
}
</style>
